/**
 * Created by mac on 18/1/16.
 */
import http from '../common/Request'
import ConfigUtil from "../common/Config.js";

const MarketingSceneService = {
    // 查询场景营销对象
    async doSearch(param) {
        var res = await http({
            url: 'marketingScene_doSearch.do',
            method: 'POST',
            data: param,
        });
        return res;
    },
    // 根据场景营销的id查询对应的场景营销
    async doGet(param) {
        var res = await http({
            url: 'marketingScene_doGet.do',
            method: 'POST',
            data: param,
        });
        return res.data.data;
    },
    async doGetSimple(param) {
        var res = await http({
            url: 'marketingScene_doGetSimple.do',
            method: 'POST',
            data: param,
        });
        return res.data.data;
    },
    async doPage(param, page, count) {
        param.page = page;
        param.count = count;
        var res = await http({
            url: 'openMarketingScene_doPage.do',
            method: 'POST',
            data: param
        });
        return res.data;
    },
    async doPageNearMarketingScene(param, page, count) {
        param.page = page;
        param.count = count;
        var res = await http({
            url: 'marketingScene_doPageNearMarketingScene.do',
            method: 'POST',
            data: param
        });
        return res.data;
    },
    async doJoin(userId, jionUserId, rootTradingAreaId) {
        var res = await http({
            url: 'marketingScene_doJion.do',
            method: 'POST',
            data: {
                userId: userId,
                jionUserId: jionUserId,
                rootTradingAreaId: rootTradingAreaId,
                groupId: ConfigUtil.getBusinessTradingAreaId(),
                marketingSceneType: 1014
            }
        });
        return res.data;
    },
    async doGetMarketingSceneProgress(userId, marketingSceneId, conditionType) {
        var res = await http({
            url: 'marketingScene_doGetMarketingSceneProgress.do',
            method: 'POST',
            data: {userId: userId, conditionType: conditionType, marketingSceneId: marketingSceneId}
        });
        return res.data.data;
    },
    async doPageJoinUsers(param, page, count) {
        param.page = page;
        param.count = count;
        var res = await http({
            url: 'marketingScene_doPageJionUsers.do',
            method: 'POST',
            data: param
        });
        return res.data;
    },
    async doGetJionRelation(rootTradingAreaId) { //获取邀请关系
        var res = await http({
            url: 'marketingScene_doGetJionRelation.do',
            method: 'POST',
            data: {rootTradingAreaId: rootTradingAreaId, marketingSceneType: 1004}
        });
        return res.data;
    },
    async doMarketingSceneCondition(marketingSceneId, doConditionId) {
        var res = await http({
            url: 'marketingScene_doMarketingSceneCondition.do',
            method: 'POST',
            data: {marketingSceneId: marketingSceneId, doConditionId: doConditionId}
        });
        return res.data;
    },
	async doAddCompanyCoupon(targetValue, marketingSceneType,groupId) {
	    var res = await http({
	        url: 'marketingScene_doMarketingSceneCondition.do',
	        method: 'POST',
	        data: {targetValue: targetValue, marketingSceneType: marketingSceneType,groupId:groupId}
	    });
	    return res.data;
	},
    async doGetDynamicFormItemValue(moduleId, dfkey, modelId) {
        var res = await http({
            url: 'dyform_doGetDynamicFormItemValue.do',
            method: 'POST',
            data: {moduleId: moduleId, dfkey: dfkey, modelId: modelId}
        });
        return res.data;
    },
    async doGetMarketingScene(userId, groupId, areaId, marketingSceneType, locationPath, pageLocation) {
        var res = await http({
            url: 'marketingScene_doGetMarketingScene.do',
            method: 'POST',
            data: {
                userId: userId,
                groupId: groupId,
                areaId: areaId,
                marketingSceneType: marketingSceneType,
                locationPath: locationPath,
                pageLocation: pageLocation
            }
        });
        return res.data;
    },
	//广告列表
	async doSearchMarketingScene(userId, groupId, areaId, marketingSceneType, locationPath, pageLocation) {
	    var res = await http({
	        url: 'marketingScene_doSearchMarketingScene.do',
	        method: 'POST',
	        data: {
	            userId: userId,
	            groupId: groupId,
	            areaId: areaId,
	            marketingSceneType: marketingSceneType,
	            locationPath: locationPath,
	            pageLocation: pageLocation==1?'ff80808151591427015159272c2100dt':pageLocation==2?'ff80808151591427015159272c2100du':'ff80808151591427015159272c2100dy'
	        }
	    });
	    return res.data;
	},
    async doSearchUserGifts(marketingSceneId, activeStatus) { //获取剩余抽奖次数和参与人数
        var res = await http({
            url: 'conditionGift_doSearchUserGifts.do',
            data: {marketingSceneId: marketingSceneId, activeStatus: activeStatus},
        });
        return res.data;
    },
    async doAU(data) { //获取剩余抽奖次数和参与人数
        var res = await http({
            url: 'marketingScene_doAU.do',
            data: data,
            bindToken:true
        });
        return res.data.data;
    },
    async doStatisticalMarketingSceneCount(param) {
        var res = await http({
            url: 'marketingScene_doStatisticalMarketingSceneCount.do',
            method: 'POST',
            data: param
        });
        return res.data.data;
    },
    async doUpdateStatus(data) { //获取剩余抽奖次数和参与人数
        var res = await http({
            url: 'marketingScene_doUpdateStatus.do',
            data: data,
            bindToken:true
        });
        return res.data.data;
    },
    async doDelete(data) { //获取剩余抽奖次数和参与人数
        var res = await http({
            url: 'marketingScene_doDelete.do',
            data: data,
            bindToken:true
        });
        return res.data.data;
    },
    async doGetTargetValue(giftParam) {
        var res = await http({
            url: 'conditions_doGetTargetValue.do',
            data: {giftParam:giftParam}
        });
        return res.data.data;
    },
    async doSearchConditionGiftDrawItemLogs(businessId) {
        var res = await http({
            url: 'conditionGift_doSearchConditionGiftDrawItemLogs.do',
            data: {businessId:businessId}
        });
        return res.data.data;
    },
    async doGetMaximumDiscountScheme(goodss) {
        var res = await http({
            url: 'marketingScene_doGetMaximumDiscountScheme.do',
            data: {goodss:goodss}
        });
        return res.data.data;
    },

}
export default MarketingSceneService
