<template>
  <div v-if="isEdit&& show">
    <el-row v-for="(dyform,index) in dyforms" :key="'dyform_'+index" :id="dyform.moduleId">
      <p class="form-title">{{dyform.title}}</p>
        <dynamic-form-for-archive :dyform="dyform" :moduleId="moduleId" :size="'medium'" :col="1" :modelId="modelId"  ref="dynamicFormsForArchiveReadonly"></dynamic-form-for-archive>
    </el-row>
  </div>
  <div v-else>
    <el-row v-for="(dyform,index) in dyforms" :key="'dyform_'+index" :id="dyform.moduleId">
      <p class="form-title">{{dyform.title}}</p>
      <dynamic-form-for-archive-readonly :dyform="dyform" :moduleId="moduleId" :modelId="modelId"  ref="dynamicFormsForArchiveReadonly"></dynamic-form-for-archive-readonly>
    </el-row>
  </div>
</template>

<script>
  // import cacheCookies from '@/utils/auth'
  import DynamicFormService from '@/services/DynamicFormService.js'
  import DynamicFormForArchiveReadonly from '@/components/DynamicFormForArchiveReadonly/index'
  import DynamicFormForArchive from '@/components/DynamicFormForArchive/index'
  export default {
    name:"DynamicFormTable",
    components:{DynamicFormForArchiveReadonly,DynamicFormForArchive},
    props: {
      moduleId:{
        type: String
      },
      modelId:{
        type: String
      },
      keyId:{
        type: String
      },
      isEdit:{
        type:Boolean,
        default: false,
      }
    },
    data() {
      return {
        dyforms:[],
        show:false
      }
    },
    watch:{
      modelId:function (newValue,oldValue) {
        this.init();
      },
      moduleId:function (newValue,oldValue) {
         this.init();
      },
    },
    async mounted() {
      this.init();
    },
    methods: {
      async init(){
        this.dyforms =[]
        var dyformsRes = await DynamicFormService.doSearchByModuleId(this.moduleId);
        if (dyformsRes.status) {
          //dyformsRes.data = dyformsRes.data.sort(this.sortChangeType)
          console.log('dyforms-------:',dyformsRes.data);
          this.show = true
          this.dyforms = dyformsRes.data;
        }
      },
      doCancel() {

      },
      auCallback() {

      },
      doAU(orderId,callback){
        console.log(orderId)
        console.log(callback)
        this.$refs.dynamicFormsForArchiveReadonly[0].doAUDynamicFormItemValueRelations(orderId,callback)
      },

      async doSearchValueRelationsForTable() {
      },
    }
  }
</script>

<style lang="scss" scoped>
  .el-form-item {
    margin-bottom: 14px;
  }
</style>
