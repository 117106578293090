/*这里存放了分页加载的list的共有逻辑 */

import OrderService from '../services/OrderService'
import CartService from '../services/CartService'
import MarketingSceneService from '../services/MarketingSceneService'
import uni from "@/services/UNIService.js"

export default {
    methods: {
        toDelete(order) {

            this.$confirm('确定删除订单：' + order.items[0].goodsTitle + "？", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                
                
                await OrderService.doDeleteOrder(order.id);

                this.close();
                this.$emit('updateOrder');
                this.tabChange(this.tabCur);
                
                if (this.pageList) {
                    this.pageList.forEach(function (o) {
                        if (o.id == order.id) {
                            o.deleteStatus = 1;
                        }
                    });
                }
                // if (this.order) {
                //     uni.navigateBack({
                //         delta: 1
                //     })
                // }

            }).catch(() => {
                         
            });
            // var that = this;
            // uni.showModal({
            //     content: '确定删除订单：' + order.items[0].goodsTitle + "？",
            //     success: function (res) {
            //         if (res.confirm) {
            //             OrderService.doDeleteOrder(order.id);
            //             if (that.pageList) {
            //                 that.pageList.forEach(function (o) {
            //                     if (o.id == order.id) {
            //                         o.deleteStatus = 1;
            //                     }
            //                 });
            //             }
            //             if (that.order) {
            //                 uni.navigateBack({
            //                     delta: 1
            //                 })
            //             }

            //         }
            //     }
            // })
        },
        async doPay(order) {
            // var payOrder = await OrderService.doPay(order.id)
			// console.log(order)
			// uni.navigateTo({
			// 	url:'/pages/Order/PayOrder?orderId=' + order.id
			// })
            this.$refs.payOrder.init(order.id);

            // this.doWxRequestPayment(payOrder);
        },
		// reSubmit(order){
		// 	console.log(order.id)
		// 	// uni.navigateTo({
		// 	// 	url:'/pages/Order/ConfirmFrom?orderId=' + order.id
		// 	// })
        //     this.$refs.confirmForm.init(order.id);
		// },
        toSearchExpress(order) {
            uni.navigateTo({
                url: '/pages/order/order_express?expressNo=' + order.expressNo
            });
        },
        toCancle(order) {
            // var that = this;
            // uni.showModal({
            //     content: '确定取消订单：' + order.items[0].goodsTitle + "？",
            //     success:async function (res) {
            //         if (res.confirm) {
            //             await  OrderService.doCancleOrder(order.id);
            //             if (that.pageList) {
            //                 that.pageList.forEach(function (o) {
            //                     if (o.id == order.id) {
            //                         o.orderStatus = -2;
            //                     }
            //                 });
            //             }
            //             if (that.order) {
            //                 that.order.orderStatus = -2;
            //             }
            //         }
            //         that.$forceUpdate();
            //     }
            // })

            this.$confirm('确定取消订单：' + order.items[0].goodsTitle + "？", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await  OrderService.doCancleOrder(order.id);
                
                this.close();
                this.$emit('updateOrder');
                this.tabChange(this.tabCur);

                    if (this.pageList) {
                        this.pageList.forEach(function (o) {
                            if (o.id == order.id) {
                                o.orderStatus = -2;
                            }
                        });
                    }
                    if (this.order) {
                        this.order.orderStatus = -2;
                    }
            }).catch(() => {
                         
            });
        },
        toCancleRefund(order) {
            var that = this;
            uni.showModal({
                content: '确定取消订单：' + order.items[0].goodsTitle + "的退款？",
                success: async function (res) {
                    if (res.confirm) {
                        await  OrderService.doCancelRefund(order.id);
                        if (that.dataList) {
                            that.dataList.forEach(function (o) {
                                if (o.id == order.id) {
                                    o.refundStatus = 1;
                                }
                            });
                        }
                        if (that.order) {
                            that.order.refundStatus = 1;
                        }
                    }
                }
            })
        },
        toRefund(order) {
            uni.navigateTo({
                url: '/pages/order/order_refund_form?orderId=' + order.id
            });
        },
        toRefuseRefund(order) {
            uni.navigateTo({
                url: '/shopmgr/order/order_shop_refuse_refund_form?orderId=' + order.id
            });
        },
        toCompletedOrder(order) {


            this.$confirm('确定订单：' + order.items[0].goodsTitle + " 已收货？", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                
                
                var result = await OrderService.doCompletedOrder(order.id);
                if (result.status != 1) {
                    return
                }
                this.close();
                this.$emit('updateOrder');
                this.tabChange(this.tabCur);
                if (this.dataList) {
                    this.dataList.forEach(function (o) {
                        if (o.id == order.id) {
                            o.orderStatus = 4;
                            o.runfundStatus = 1;
                        }
                    });
                }
                if (this.order) {
                    this.order.runfundStatus = 1;
                    this.order.orderStatus = 4;
                }
            }).catch(() => {
                         
            });

            // var that = this;
            // uni.showModal({
            //     content: '确定订单：' + order.items[0].goodsTitle + " 已收货？",
            //     async success(res) {
            //         if (res.confirm) {
            //             var result = await OrderService.doCompletedOrder(order.id);
            //             if (result.status != 1) {
            //                 return
            //             }
            //             if (that.dataList) {
            //                 that.dataList.forEach(function (o) {
            //                     if (o.id == order.id) {
            //                         o.orderStatus = 4;
            //                         o.runfundStatus = 1;
            //                     }
            //                 });
            //             }
            //             if (that.order) {
            //                 that.order.runfundStatus = 1;
            //                 that.order.orderStatus = 4;
            //             }
            //         }
            //     }
            // })
        },
        async doWxRequestPayment(payOrder) {
            var sign = payOrder.weiXinPayMap;
            var that = this;
            //如果支付的是0元，默认支付成功跳转到预订单界面
			console.log(payOrder.payStatus)
            if(payOrder.payStatus==1) {
                uni.redirectTo({
                    url: "/pages/order/order_detail?orderId="+payOrder.id
                });
                that.disabled = false;
                return;
            }

            if (!sign) {
                uni.showToast({
                    title: "支付失败，支付配置有问题，请联系商家！",
                    icon: 'none',
                    mask: true,
                    duration: 2000
                });
                return;
            }

            // #ifdef MP-WEIXIN
            uni.requestPayment({
                timeStamp: sign.timeStamp,
                nonceStr: sign.nonceStr,
                package: sign.package,
                signType: 'MD5',
                paySign: sign.paySign,
                async success(successRes) {
                    var logs = await MarketingSceneService.doSearchConditionGiftDrawItemLogs(payOrder.id);
                    if(logs && logs.length>0) {
                        var item = logs[0];
                        console.log(item);
                        if(item.giftType == 9) {
                            uni.redirectTo({
                                url: "/pages/order/card_invite?cardId="+item.conditionModelItemId
                            });
                            that.disabled = false;
                            return;
                        }
                    }

                    uni.redirectTo({
                        url: "/pages/order/order_detail?orderId="+payOrder.id
                    });

                    that.disabled = false;
                },
                fail(err) {
                    console.log(err);
                    that.disabled = false;
                    uni.redirectTo({
                        url: '/pages/order/order_detail?orderId=' + payOrder.id
                    });
                }
            });
            // #endif

            // #ifdef APP-PLUS
            uni.requestPayment({
                provider: 'wxpay',
                orderInfo: JSON.stringify(sign), //微信、支付宝订单数据
                success: function (res) {
                    uni.redirectTo({
                        url: "/pages/goods/payment_result?orderId=" + payOrder.id
                    });
                    that.disabled = false;
                },
                fail: function (err) {
                    console.log(err);
                    that.disabled = false;
                    uni.redirectTo({
                        url: '/pages/order/order_detail?orderId=' + payOrder.id
                    });
                }
            });
            // #endif
        },
        async toDelivery(order) { //发货
            var [error, modalRes] = await uni.showModal({
                content: '确认发货：' + order.items[0].goodsTitle + '?'
            });
            if (!modalRes.confirm) {
                return;
            }
            if (order.orderStatus == 1) { //先确认订单，后发货
                var res = await OrderService.doConfirmOrder(order.id);
                if (res.status != 1) {
                    return;
                }
            }
            uni.navigateTo({
                url: '/shopmgr/order/order-delivery?orderId=' + order.id
            });
        },
        async addOrderNow(goods) {
            var cart = await CartService.doSetCount(null, 1, goods);
            var order = {
                orderType:1,
                sendType:4,
                appId:"wxc101caa61819ae8d",
                receiveAddressId: '',
                payTypes: 0,
                totalPrice: 0,
                paymentAmount: 0,
                integralAmount: '-',
                count: 1,
                totalOriginalPrice:0,
                groupId:goods.groupId
            };


            order.cartIds = cart.id;

            var res = await OrderService.doAdd(order);

            if (res.status != 1) {
                uni.showModal({
                    content: res.data + ',请重新下单',
                    showCancel: false,
                    success() {

                    }
                })
                return;
            }
            res.data.integralAmount = order.integralAmount;
            res.data.paymentAmount = order.paymentAmount;
            this.order = res.data;

            this.doWxRequestPayment(this.order);
        }
    },

}