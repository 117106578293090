/**
 * Created by Admin on 2017/10/10.
 */
import http from '../common/Request'
import CommonUtil from "../common/Common"
import ConfigUtil from '../common/Config.js'
import uni from '@/services/UNIService'

const OrderService = {
	async doPrePay(params) {
		var res = await http({
			url: "order_doPrePay.do",
			data: CommonUtil.doFormattedRequestData(params),
			type: "string"
		});
		return res.data
	},
	async doAdd(count) { //券预约申请
		var res = await http({
			url: "order_doAdd.do",
			data: {
				orderType:2,
				groupId:ConfigUtil.getBusinessTradingAreaId(),
				businessType:4026000000000000,
				goodsId:'ff8080816364db58016364ff57ba004k',
				count:count,
				buyerId:uni.getStorageSync('userId')
			},
		});
		return res.data
	},
	async doAddgoods(params) { //商品预约申请
	    var res = await http({
	        url: "order_doAdd.do",
	        data: CommonUtil.toSpecialParam(params),
	        type: "string"
	    });
	    return res.data
	},

	/**
	 * 订单综合查询
	 * @param param
	 * @returns {Promise.<void>}
	 */
	//获取订单列表信息
	async doPageOrder(tradingAreaId, groupId, orderStatus, refundStatus, payStatus, needItems, page, count) { //获取商户订单列表信息
		var param = {
			orderSearchModel: {
				tradingAreaId: tradingAreaId,
				groupId: groupId,
				orderStatus: orderStatus,
				refundStatus: refundStatus,
				payStatus: payStatus,
				needItems: needItems,
				deleteStatus: 0,
				orderType: 1
			},
			page: page,
			count: count
		}
		if (orderStatus && (orderStatus + "").indexOf(",") != -1) {
			param.orderSearchModel.orderStatus = null;
			param.orderSearchModel.orderStatuss = orderStatus;
		}
		if (refundStatus && (refundStatus + "").indexOf(",") != -1) {
			param.orderSearchModel.refundStatus = null;
			param.orderSearchModel.refundStatuss = refundStatus;
		}
		var res = await http({
			url: "order_doPage.do",
			data: param
		})
		return res.data
	},
	async doConfirmOrder(orderId) { //确认订单
		var res = await http({
			url: 'order_doConfirmOrder.do',
			data: {
				id: orderId,
			},
			bindToken: true
		});
		return res.data;
	},
	/**
	 * 自动订单确认-2022.5.23
	 * @param {Object} orderId
	 * @param {Object} token
	 */
	 async doAutoConfirmOrder(orderId,token) { //确认订单
		var res = await http({
			url: 'order_doConfirmOrder.do',
			data: {
				id: orderId,
				token:token
			},
		});
		return res.data;
	},
	async doConfirmSend(orderId, expressNo) { //发货
		var res = await http({
			url: 'order_doConfirmSend.do',
			data: {
				id: orderId,
				expressNo: expressNo,
			},
			bindToken: true
		});
		return res.data;
	},
	//获取订单列表信息
	async doPageOrdersShop(tradingAreaId, buyerId, orderStatus, refundStatus, payStatus, needItems, page, count) {
		var param = {
			orderSearchModel: {
				tradingAreaId: tradingAreaId,
				buyerId: buyerId,
				orderStatus: orderStatus,
				refundStatus: refundStatus,
				payStatus: payStatus,
				needItems: needItems,
				deleteStatus: 0,
				orderType: 1
			},
			page: page,
			count: count
		}
		if (orderStatus && (orderStatus + "").indexOf(",") != -1) {
			param.orderSearchModel.orderStatus = null;
			param.orderSearchModel.orderStatuss = orderStatus;
		}
		if (refundStatus && (refundStatus + "").indexOf(",") != -1) {
			param.orderSearchModel.refundStatus = null;
			param.orderSearchModel.refundStatuss = refundStatus;
		}
		var res = await http({
			url: "order_doPage.do",
			data: param
		})
		return res.data
	},
	async doDeleteOrder(id) {
		var res = await http({
			url: "order_doDeleteOrder.do",
			data: {
				id: id
			}
		})
		return res.data
	},
	async doCancleOrder(id) {
		var res = await http({
			url: "order_doCancleOrder.do",
			data: {
				id: id
			}
		})
		return res.data
	},
	async doCancelRefund(id) {
		var res = await http({
			url: "order_doCancelRefund.do ",
			data: {
				orderId: id
			}
		})
		return res.data
	},
	async doGetCouponItem(cardNumber) { //获取订单状态
		var res = await http({
			url: "openCoupon_doGetCouponItem.do",
			data: {
				cardNumber: cardNumber
			}
		})
		return res.data
	},
	async doCompletedOrder(id) {
		var res = await http({
			url: "order_doCompletedOrder.do",
			data: {
				id: id
			}
		})
		return res.data
	},
	//获取订单详情信息
	async doGetOrders(param) {
		var res = await http({
			url: "openThirdParty_doGetOrders.do",
			data: param
		})
		return res.data.data
	},
	//获取订单详情信息-商城
	async doGetOrderById(id) {
		var res = await http({
			url: "order_doGet.do",
			data: {
				id: id
			}
		})
		return res.data;
	},
	async doPayOrder(payForId) {
		var res = await http({
			url: "cashierPay_doMarketingPay.do",
			data: {
				payForId: payForId,
				appId: ConfigUtil.getWXAAppId()
			}
		});
		if (res.data.status == 1 && res.data.data) {
			return res.data.data;
		}
		return null;
	},
	async doRePay(order) {
		order.appId = ConfigUtil.getWXAAppId();
		// #ifdef APP-PLUS
		order.appId = ConfigUtil.getAppAppId();
		// #endif

		var res = await http({
			url: "order_doRePay.do",
			data: CommonUtil.toSpecialParam(order),
			type: "string"
		});

		if (res.data.status == 1 && res.data.data) {
			return res.data.data;
		}
	},
	async doPay(payForId) {
		// #ifdef MP-WEIXIN
		var res = await http({
			url: "cashierPay_doPay.do",
			data: {
				payForId: payForId,
				appId: ConfigUtil.getWXAAppId()
			}
		});
		// #endif

		// #ifdef APP-PLUS
		var res = await http({
			url: "cashierPay_doPay.do",
			data: {
				payForId: payForId,
				appId: ConfigUtil.getAppAppId()
			}
		});
		// #endif

		if (res.data.status == 1 && res.data.data) {
			return res.data.data;
		}
		return null;
	},
	//获取物流信息 --根据用户获得得物流信息
	async doSearchUserOrderExpress(groupId) {
		var res = await http({
			url: "order_doSearchUserOrderExpress.do",
			data: {
				groupId: groupId
			}
		});
		return res.data;
	},
	//获取订单笔数--商城
	async doStatisticsOrderStatus(userId) {
		var param = {
			orderSearchModel: {
				buyerId: userId,
				payStatus: 1,
				tradingAreaId: ConfigUtil.getBusinessTradingAreaId(),
				refundStatus: 1
			}
		}
		var res = await http({
			url: "order_doStatisticsOrderStatus.do",
			data: param
		});
		if (res.data.status == 1 && res.data.data) {
			return res.data.data;
		}
		return new Array();
	},
	//获取物流信息
	async doSearchexpress(param) {
		var res = await http({
			url: "express_doSearchexpress.do",
			data: param
		})
		return res.data
	},
	async doStatisticalTotalCount(searchModel) {
		var param = {
			orderSearchModel: searchModel
		}
		var res = await http({
			url: "order_doStatisticalTotalCount.do",
			data: param
		});
		if (res.data.status == 1 && res.data.data) {
			return res.data.data;
		}
		return 0;
	},
	async doStatisticalTotalUser(groupId, startOrderDate, endOrderDate, businessType, orderStatus) { //获取商户今日客户数
		var res = await http({
			url: "order_doStatisticalTotalUser.do",
			data: {
				orderSearchModel: {
					groupId: groupId,
					startOrderDate: startOrderDate,
					endOrderDate: endOrderDate,
					businessType: businessType,
					orderStatus: orderStatus
				}
			}
		});
		return res.data
	},
	async doStatisticalTotalAmount(groupId, startPayDate, endPayDate, orderStatus, captainUserId, regimentalUserId) { //获取商户销售金额
		var res = await http({
			url: "order_doStatisticalTotalAmountNew.do",
			data: {
				orderSearchModel: {
					ptradingAreaId: groupId,
					startPayDate: startPayDate,
					endPayDate: endPayDate,
					orderStatuss: orderStatus,
					captainUserId: captainUserId,
					regimentalUserId: regimentalUserId,
					payStatus: 1,
					orderType: 1,
				}
			}
		})
		return res.data
	},
	async doStatisticalCaptainAmount(groupId, startPayDate, endPayDate, orderStatus, captainUserId, refundStatus) { //队长佣金
		var res = await http({
			url: "order_doStatisticalCaptainAmount.do",
			data: {
				orderSearchModel: {
					startPayDate: startPayDate,
					orderStatus: orderStatus, //有效需要
					endPayDate: endPayDate,
					captainUserId: captainUserId,
					refundStatus: refundStatus,
					payStatus: 1
				}
			}
		})
		return res.data
	},
	async doStatisticalRegimentalAmount(groupId, startPayDate, endPayDate, orderStatus, regimentalUserId, refundStatus) { //团长佣金
		var res = await http({
			url: "order_doStatisticalRegimentalAmount.do",
			data: {
				orderSearchModel: {
					startPayDate: startPayDate,
					endPayDate: endPayDate,
					orderStatus: orderStatus, //有效需要
					regimentalUserId: regimentalUserId,
					refundStatus: refundStatus,
					payStatus: 1
				}
			}
		})
		return res.data
	},
	async doPage(groupId, startOrderDate, endOrderDate, orderStatus, regimentalUserId, captainUserId, count, page) { //获取订单
		var res = await http({
			url: "order_doPage.do",
			data: {
				orderSearchModel: {
					needItems: 1,
					ptradingAreaId: groupId,
					startOrderDate: startOrderDate,
					endOrderDate: endOrderDate,
					orderStatus: orderStatus,
					captainUserId: captainUserId,
					regimentalUserId: regimentalUserId,
					payStatus: 1,
					orderType: 1,
				},
				count: count,
				page: page,
			}
		})
		return res.data
	},
	async doPageSelf(tradingAreaId, startOrderDate, endOrderDate, orderStatus, count, page) {
		var res = await http({
			url: "order_doPage.do",
			data: {
				orderSearchModel: {
					tradingAreaId: tradingAreaId,
					payStatus: 1,
					needItems: 1,
					deleteStatus: 0,
					startOrderDate: startOrderDate,
					endOrderDate: endOrderDate,
					orderType: 1,
					orderStatus: orderStatus,
				},
				count: count,
				page: page,
			}
		})
		return res.data
	},
	async doSearchMoney(tradingAreaId, buyerId, groupId, businessType, startOrderDate, endOrderDate, orderType, count,
		page) { //提现订单
		var res = await http({
			url: "order_doPage.do",
			data: {
				orderSearchModel: {
					tradingAreaId: tradingAreaId,
					buyerId: buyerId,
					businessType: businessType,
					deleteStatus: 0,
					startOrderDate: startOrderDate,
					endOrderDate: endOrderDate,
					orderType: orderType,
					groupId: groupId,
				},
				count: count,
				page: page,
			}
		});
		return res.data
	},
	async doMerchantsWithdrawal(params) { //商户提现
		var res = await http({
			url: "order_doAdd.do",
			data: CommonUtil.toSpecialParam(params),
			type: "string",
			bindToken: true,
		});
		return res.data
	},
	async doApplyForRefund(params) { //申请退款
		var res = await http({
			url: "order_doApplyForRefund.do",
			data: params
		});
		return res.data
	},
	async doRefund(params) { //确认退款
		var res = await http({
			url: "order_doRefund.do",
			data: params,
			bindToken: true
		});
		return res.data
	},
	async doRefuseRefund(params) { //确认退款
		var res = await http({
			url: "order_doRefuseRefund.do",
			data: params,
			bindToken: true
		});
		return res.data
	},
	async doPageJionUsers(marketingSceneId, count, page) { //获取订单
		var res = await http({
			url: "order_doPage.do",
			data: {
				orderSearchModel: {
					marketingSceneId: marketingSceneId,
					needItems: 1
				},
				count: count,
				page: page
			}
		})
		return res.data
	},
	async doStatistic(sm, osm, page, count) { //获取订单
		var res = await http({
			url: "order_doStatistic.do",
			data: {
				orderSearchModel: osm,
				sm: sm,
				count: count,
				page: page
			},
			bindToken: true
		})
		return res.data
	},
	async doStatisticalAmount(param) { //获取商户销售金额
		var res = await http({
			url: "order_doStatisticalTotalAmountNew.do",
			data: {
				orderSearchModel: param
			}
		})
		return res.data.data
	},
	async doStatisticalRefundAmount(param) { //获取商户销售金额
		var res = await http({
			url: "order_doStatisticalRefundAmount.do",
			data: {
				orderSearchModel: param
			}
		})
		return res.data.data
	},
	async doStatisticalCaptainAmountNew(param) { //获取商户销售金额
		var res = await http({
			url: "order_doStatisticalCaptainAmount.do",
			data: {
				orderSearchModel: param
			}
		})
		return res.data.data
	},
	// async doStatisticalRegimentalAmountNew(param) { //获取商户销售金额
	// 	var res = await http({
	// 		url: "order_doStatisticalRegimentalAmount.do",
	// 		data: {
	// 			orderSearchModel: param
	// 		}
	// 	})
	// 	return res.data.data
	// },
	async doPageNew(param, page, count) { //获取商户销售金额
		param.orderStatuss = null;
		param.refundStatuss = null;
		if (param.orderStatus && (param.orderStatus + "").indexOf(",") != -1) {
			param.orderStatuss = param.orderStatus;
			param.orderStatus = null;
		}

		if (param.refundStatus && (param.refundStatus + "").indexOf(",") != -1) {
			param.refundStatuss = param.refundStatus;
			param.refundStatus = null;
		}

		var res = await http({
			url: "order_doPage.do",
			data: {
				orderSearchModel: param,
				count: count,
				page: page
			}
		})
		return res.data
	},
	async doStatisticalRegimentalAmountNew(orderSearchModel) { //创新券接口
		var res = await http({
			// url: "order_doSumTotalPrice.do",
			url: "apiOrder_doSumTotalPrice.do",
			data: {
				orderSearchModel:orderSearchModel
			}
		})
		return res.data
	},
	async doGetConsumeAmount(couponLog) { //获取创新券兑付金额
		var res = await http({
			// url: "coupon_doGetConsumeAmount.do",
			url: "openCoupon_doGetConsumeAmount.do",
			data: {
				couponLog:couponLog
			}
		})
		return res.data
	},
	async doAudit(orderId, auditState) { //修改订单审核状态
	    var res = await http({
	      url: "order_doUpdateAuditState.do",
	      data: {
	        orderId:orderId,
	        auditState:auditState
	      }
	    })
	    return res.data
	},
	/**
	 * 订单自动审核-自动进入申请提现状态--2022.5.23
	 * @param {Object} orderId
	 * @param {Object} auditState
	 */
	 async doAutoAudit(orderId, auditState, token) { 
	    var res = await http({
	      url: "order_doUpdateAuditState.do",
	      data: {
	        orderId:orderId,
	        auditState:auditState,
			token:token
	      }
	    })
	    return res.data
	},
}
export default OrderService
