/* eslint-disable indent */
/**
 * Created by Admin on 2017/10/9.
 */
import http from '../common/Request'
const CartService = {
    async doGetCount(groupId, userId) {
        var res = await http({
          url: "cart_doGetGoodsCount.do",
          method: "POST",
          data: {groupId:groupId, userId:userId}

        });
        return res.data.data
    },
    async doSetCount(cartId, count, goods) {
        var res = await http({
            url: "cart_doSetCount.do",
            method: "POST",
            data: {id:cartId,count:count,goodsId:goods.id,groupId:goods.groupId}
        });
        return res.data.data
    },
    async doSearch(groupId, userId) {
        var res = await http({
            url: "cart_doSearch.do",
            method: "POST",
            data:{groupId:groupId, userId:userId}
        });
        return res.data.data
    },
    async doDelete(cartId) {
        var res = await http({
            url: "cart_doDelete.do",
            method: "POST",
            data:{id:cartId}
        });
        return res.data.data
    }
}

export default CartService
