import http from '../common/Request'

const InvoiceService = {
	async doWxChooseInvoice(type, title, bank, BankAccount, site, phone, dutyParagraph) {
		var res = await http({
			url: "invoice_doWxChooseInvoice.do",
			method: "POST",
			data: {
				type: type,
				title: title,
				bank: bank,
				BankAccount: BankAccount,
				site: site,
				phone: phone,
				dutyParagraph: dutyParagraph
			}
		})
		return res.data
	},
	async doGetInvoice(id) {
		var res = await http({
			url: "invoice_doGetInvoice.do",
			method: "POST",
			data: {
				id: id
			}
		})
		if (res.data.status == 1 && res.data.data) {
			return res.data.data;
		}
		return null;
	}
}
export default InvoiceService
