<template>
  <div class="goods-item-box clearfix">
    <div class="goods-left left">
        <el-image
        style="width: 120px; height: 120px"
        :src="goods.showUrl?goods.showUrl.split(',')[0]:goods.showGoodsImageUrl?goods.showGoodsImageUrl.split(',')[0]:'https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/goods_default.png'"
        :alt="goods.title"
        fit="cover"></el-image>
    </div>

    <div class="goods-right left">
        <div class="product-title">
            <div class="name one-omit">{{goods.title||goods.goodsTitle}}</div>
        </div>
        <div class="pdt-nums-content" v-if='step'>
            <span class="tit">数量:</span> 
            <span class="pdt-nums">
                <el-button class="btn-minus" :disabled="goods.cartCount<=1" size="mini" @click="goods.cartCount--"> - </el-button>
                <!-- <el-input v-model="goods.cartCount" placeholder="数量"  class="input-nums"  :disabled="true" size="mini"></el-input> -->
                <span class="pdt-count">  {{goods.cartCount}} </span>
                <el-button class="btn-plus" size="mini" @click="goods.cartCount++"> + </el-button>
            </span>
        </div>
        <div class="pdt-nums-content" v-else>
           <span class="tit">数量:</span> 
            <span class="pdt-nums">
              {{goods.count}}
            </span>
        </div>
        <div class="pdt-price-content" v-if="(goods.presentPrice?goods.presentPrice:goods.goodsPrice)>0">
            <span class="tit">价格:</span> <span> <span class="price-symbol">¥</span> <span class="pdt-price">{{goods.presentPrice?goods.presentPrice:goods.goodsPrice}}</span> <span class="price-unit">{{goods.unitName}}</span> </span>
            <!-- <img :src="require('../../assets/img/coupon_icon.png')" width="78px" class="product-coupon" height="25px" alt="创新优惠券" v-if="goods.discountStatus"> -->
        </div>
        <div class="pdt-price-content" v-else>
            按需求报价
        </div>
        
        <el-button class="advisory-btn" v-if="showAdvisoryBtn" size="small" @click="callPhone(goods.groupId)">联系商家</el-button>
    </div>
  </div>
</template>
<script>
import GroupService from '../../services/GroupService'

export default {
  name: 'com-item-goods-item',
  data() {
    return {

    };
  },
  components:{
  },
  props: {
    goods: {
      type: Object,
      default() {
        return {};
      }
    },
    step: {
      type: Boolean,
      default() {
        return true
      }
    },
    cartModel: {
      type: Boolean,
      default() {
        return false;
      }
    },
    shareMoney: {
      type: Boolean,
      default() {
        return true;
      }
    },
    refName: {
      type: String,
      default() {
        return "";
      }
    },
    showAdvisoryBtn: {
      type: Boolean,
      default() {
        return true;
      }
    },
  },
  created() {
    // var nickName = uni.getStorageSync("nickName");
    // if(nickName) {
    //   this.userAuth = true;
    // }
  },
  methods: {
    async callPhone(groupId){
        
        let group = await GroupService.doGetOneGroup(this.goods.groupId);
        let groupContact='暂无联系方式';
        if(group.contact){
          groupContact=group.contact;
        }
        this.$confirm(groupContact + '', '联系电话', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          
        }).catch(() => {
                  
        });
    },
    goodsItemCartChange(goods, count){
      console.log(this.refName+"com-item-goods-item,count:"+count+",goods:"+goods.title);
      this.$emit('cartChange', goods, count);
    },
    toGoodsDetail() {
      this.$emit('toGoodsDetail', this.goods);
    }
  },
  watch: {
    goods(val){
      console.log(this.refName+"com-item-goods-item,watch goods:"+val.title);
    }
  }
};
</script>


<style lang="scss" scoped>

.goods-item-box{
    position: relative;
    margin:5px auto 5px;
    width:100%;
    

    .goods-left{
        width:114px;
        height:114px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        img{
            width:100%;
        }

        .product-coupon{
                position: absolute;
            top:15px;
            right:15px;
            z-index: 20;
            width:105px;
            height:34px;
        }


    }

    .goods-right{
        margin-left:25px;
        width:calc(100% - 139px);
        height:120px;
        text-align: left;
        position: relative;

        .product-title{
            font-size:20px;
            height: 36px;
            line-height: 36px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .tit{
            color:#333;
            font-weight: 600;
        }
       
        
        .pdt-nums-content{
            // margin-top: 5px;
            height: 40px;
            line-height: 40px;
            font-size:18px;
            .tit{
                // line-height:36px;
                vertical-align: middle;
            }
            .pdt-nums{
                margin-left: 10px;
                vertical-align: middle;

                /deep/.btn-minus.el-button--mini,.btn-plus.el-button--mini{
                    vertical-align: middle;
                    padding:6px 0;
                    width: 28px;
                    text-align: center;
                }
                .input-nums{
                    width: 80px;
                    margin:-2px 5px 0;
                    vertical-align: middle;
                }

                .pdt-count{
                  display: inline-block;
                  width: 50px;
                  border: 1px solid #DCDFE6;
                  height: 26px;
                  line-height: 26px;
                  vertical-align: middle;
                  margin:0 5px;
                  border-radius:3px;
                  padding:0 6px;
                  font-size: 14px;
                }
                
            }
        }

        .pdt-price-content{

            height:36px;
            font-size:18px;
            // margin-top: 10px;
            // margin-bottom: 24px;
            

            .price-symbol{
                font-weight:600;
                color:#e61414;
            }
            .pdt-price{
                font-size: 24px;
                color:#e61414;
                font-weight:600;
            }

            .price-unit{
                    font-weight:600;
            }

            .product-coupon{
                vertical-align: bottom;
                margin-left: 25px;
            }
        }

        .advisory-btn{
            padding:0 10px;
            position: absolute;
            bottom:2px;
            right:0;
            width:128px;
            height:36px;
            line-height:34px;
            font-size: 16px;
            color:#fff;
            background: #2b87fe;
        }

       

        

           
            
            
        
    }

}
</style>
